import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { api_url } from "Variables";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { LazyLoadImage } from "react-lazy-load-image-component";

gsap.registerPlugin(ScrollTrigger);

/* //TODO: ----------------------------------- TODO ----------------------------------- */
// Add Click Functionality in Expertise

function AboutExpertise() {
	const [exp, setExp] = useState([]);
	const headingRef = useRef(null);
	const [tab1, setTab1] = useState(true);
	const [tab2, setTab2] = useState(false);
	const [tab3, setTab3] = useState(false);
	const [tab4, setTab4] = useState(false);
	const [tab5, setTab5] = useState(false);
	const [tab6, setTab6] = useState(false);
	const [tab7, setTab7] = useState(false);
	const [tab8, setTab8] = useState(false);
	const [tab9, setTab9] = useState(false);

	useEffect(() => {
		AboutUs();
	}, []);

	useLayoutEffect(() => {
		ScrollTrigger.refresh();
		const expHeading = document.getElementById("expHeading");
		if (expHeading) {
			gsap.to(expHeading, {
				x: 150,
				duration: 0.55,
				scrollTrigger: {
					trigger: expHeading,
					start: "-150px center",
					end: "550px center",
					scrub: 1,
				},
			});
		}
	});

	const AboutUs = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "about-us?custom_sort=1", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setExp([...result]);
				console.log("Expertise", exp);
			})
			.catch((error) => console.log("error", error));
	};

	return (
		<div>
			<section class="experties primary-bg white--cursor">
				<div class="line-heading translateX-right">
					{exp
						? exp.length > 0
							? exp.map((n, index) => (
									<h3 class="outline-text" id="expHeading" ref={headingRef}>
										{n["expertise-title"]}
									</h3>
							  ))
							: ""
						: ""}
				</div>
				<div class="experties__tabs-wrapper">
					<div class="twocol-row">
						{exp
							? exp.length > 0
								? exp.map((n, index) => (
										<div class="col-20">
											<div
												class="experties__tabs-wrapper--detail-tab"
												id="tab-1"
											>
												<div class="img-box">
													<LazyLoadImage
														class="lazy-img"
														src={n["expertise-3-icon"].full_url}
														alt="img"
													/>
												</div>
												<div class="generic--head">
													<p class="generic--head__sec-para s-font">
														{n["expertise-main-description"]}
													</p>
												</div>
											</div>
										</div>
								  ))
								: ""
							: ""}

						<div class="col-60">
							{exp
								? exp.length > 0
									? exp.map((n, index) => (
											<ul>
												<li>
													<div
														class={
															tab1
																? "experties__tabs-wrapper--small-cards active"
																: "experties__tabs-wrapper--small-cards"
														}
														onClick={() => {
															setTab1(true);
															setTab2(false);
															setTab3(false);
															setTab4(false);
															setTab5(false);
															setTab6(false);
															setTab7(false);
															setTab8(false);
															setTab9(false);
														}}
													>
														<div class="img-box">
															<LazyLoadImage
																class="lazy-img"
																src={n["expertise-1-icon"].full_url}
																alt="img"
															/>
														</div>
														<div class="generic--head">
															<h6 class="generic--head__sec-para s-font">
																{n["expertise-1-description"]}
															</h6>
														</div>
													</div>
												</li>
												<li>
													<div
														class={
															tab2
																? "experties__tabs-wrapper--small-cards active"
																: "experties__tabs-wrapper--small-cards"
														}
														onClick={() => {
															setTab1(false);
															setTab2(true);
															setTab3(false);
															setTab4(false);
															setTab5(false);
															setTab6(false);
															setTab7(false);
															setTab8(false);
															setTab9(false);
														}}
													>
														<div class="img-box">
															<LazyLoadImage
																class="lazy-img"
																src={n["expertise-2-icon"].full_url}
																alt="img"
															/>
														</div>
														<div class="generic--head">
															<h6 class="generic--head__sec-para s-font">
																{n["expertise-2-description"]}
															</h6>
														</div>
													</div>
												</li>
												<li>
													<div
														class={
															tab3
																? "experties__tabs-wrapper--small-cards active"
																: "experties__tabs-wrapper--small-cards"
														}
														onClick={() => {
															setTab1(false);
															setTab2(false);
															setTab3(true);
															setTab4(false);
															setTab5(false);
															setTab6(false);
															setTab7(false);
															setTab8(false);
															setTab9(false);
														}}
													>
														<div class="img-box">
															<LazyLoadImage
																class="lazy-img"
																src={n["expertise-3-icon"].full_url}
																alt="img"
															/>
														</div>
														<div class="generic--head">
															<h6 class="generic--head__sec-para s-font">
																{n["expertise-3-description"]}
															</h6>
														</div>
													</div>
												</li>
												<li>
													<div
														class={
															tab4
																? "experties__tabs-wrapper--small-cards active"
																: "experties__tabs-wrapper--small-cards"
														}
														onClick={() => {
															setTab1(false);
															setTab2(false);
															setTab3(false);
															setTab4(true);
															setTab5(false);
															setTab6(false);
															setTab7(false);
															setTab8(false);
															setTab9(false);
														}}
													>
														<div class="img-box">
															<LazyLoadImage
																class="lazy-img"
																src={n["expertise-4-icon"].full_url}
																alt="img"
															/>
														</div>
														<div class="generic--head">
															<h6 class="generic--head__sec-para s-font">
																{n["expertise-4-description"]}
															</h6>
														</div>
													</div>
												</li>
												<li>
													<div
														class={
															tab5
																? "experties__tabs-wrapper--small-cards active"
																: "experties__tabs-wrapper--small-cards"
														}
														onClick={() => {
															setTab1(false);
															setTab2(false);
															setTab3(false);
															setTab4(false);
															setTab5(true);
															setTab6(false);
															setTab7(false);
															setTab8(false);
															setTab9(false);
														}}
													>
														<div class="img-box">
															<LazyLoadImage
																class="lazy-img"
																src={n["expertise-5-icon"].full_url}
																alt="img"
															/>
														</div>
														<div class="generic--head">
															<h6 class="generic--head__sec-para s-font">
																{n["expertise-5-description"]}
															</h6>
														</div>
													</div>
												</li>
												<li>
													<div
														class={
															tab6
																? "experties__tabs-wrapper--small-cards active"
																: "experties__tabs-wrapper--small-cards"
														}
														onClick={() => {
															setTab1(false);
															setTab2(false);
															setTab3(false);
															setTab4(false);
															setTab5(false);
															setTab6(true);
															setTab7(false);
															setTab8(false);
															setTab9(false);
														}}
													>
														<div class="img-box">
															<LazyLoadImage
																class="lazy-img"
																src={n["expertise-6-icon"].full_url}
																alt="img"
															/>
														</div>
														<div class="generic--head">
															<h6 class="generic--head__sec-para s-font">
																{n["expertise-7-description"]}
															</h6>
														</div>
													</div>
												</li>
												<li>
													<div
														class={
															tab7
																? "experties__tabs-wrapper--small-cards active"
																: "experties__tabs-wrapper--small-cards"
														}
														onClick={() => {
															setTab1(false);
															setTab2(false);
															setTab3(false);
															setTab4(false);
															setTab5(false);
															setTab6(false);
															setTab7(true);
															setTab8(false);
															setTab9(false);
														}}
													>
														<div class="img-box">
															<LazyLoadImage
																class="lazy-img"
																src={n["expertise-7-icon"].full_url}
																alt="img"
															/>
														</div>
														<div class="generic--head">
															<h6 class="generic--head__sec-para s-font">
																{n["expertise-8-description"]}
															</h6>
														</div>
													</div>
												</li>
												<li>
													<div
														class={
															tab8
																? "experties__tabs-wrapper--small-cards active"
																: "experties__tabs-wrapper--small-cards"
														}
														onClick={() => {
															setTab1(false);
															setTab2(false);
															setTab3(false);
															setTab4(false);
															setTab5(false);
															setTab6(false);
															setTab7(false);
															setTab8(true);
															setTab9(false);
														}}
													>
														<div class="img-box">
															<LazyLoadImage
																class="lazy-img"
																src={n["expertise-8-icon"].full_url}
																alt="img"
															/>
														</div>
														<div class="generic--head">
															<h6 class="generic--head__sec-para s-font">
																{n["expertise-9-description"]}
															</h6>
														</div>
													</div>
												</li>
												<li>
													<div
														class={
															tab9
																? "experties__tabs-wrapper--small-cards active"
																: "experties__tabs-wrapper--small-cards"
														}
														onClick={() => {
															setTab1(false);
															setTab2(false);
															setTab3(false);
															setTab4(false);
															setTab5(false);
															setTab6(false);
															setTab7(false);
															setTab8(false);
															setTab9(true);
														}}
													>
														<div class="img-box">
															<LazyLoadImage
																src={n["expertise-9-icon"].full_url}
																alt="img"
															/>
														</div>
														<div class="generic--head">
															<p class="generic--head__sec-para s-font">
																Resource Outsourcing
															</p>
														</div>
													</div>
												</li>
											</ul>
									  ))
									: ""
								: ""}
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default AboutExpertise;
