import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import drag from "../../Assets/Images/drag-img.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import { Scrollbar } from "swiper/modules";
import { api_url } from "Variables";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { LazyLoadImage } from "react-lazy-load-image-component";

gsap.registerPlugin(ScrollTrigger);

/*! //!-------------------------------- Completed ------------------------------- */

function Blog() {
	const [blogs, setBlogs] = useState("");
	const headingRef = useRef(null);

	useEffect(() => {
		getAllBlogs();
	}, []);

	useLayoutEffect(() => {
		ScrollTrigger.refresh();
		const blogHeading = document.getElementById("blogHeading");
		if (blogHeading) {
			gsap.to(blogHeading, {
				x: 150,
				duration: 0.55,
				scrollTrigger: {
					trigger: blogHeading,
					start: "-150px center",
					end: "350px center",
					scrub: 1,
				},
			});
		}
	});

	function formatDateToDdMmYyyy(dateString) {
		const date = new Date(dateString);
		const day = date.getDate().toString().padStart(2, "0");
		const month = (date.getMonth() + 1).toString().padStart(2, "0");
		const year = date.getFullYear();
		return `${day}.${month}.${year}`;
	}
	
	const getAllBlogs = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "posts?custom_sort=1", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setBlogs(result);
			})
			.catch((error) => console.log("error", error));
	};

	return (
		<section className="blog section">
			<div className="line-heading" id="blogHeading" ref={headingRef}>
				<h3 className="outline-text">Worth A Read</h3>
			</div>
			<Swiper
				loop={true}
				centeredSlides={true}
				slidesPerView={4.5}
				spaceBetween={20}
				speed={2000}
				scrollbar={true}
				modules={[Scrollbar]}
				className="mySwiper services-slider swiper worth-read__Slider"
				breakpoints={{
					300: {
						slidesPerView: 1,
						spaceBetween: 5,
					},
					600: {
						slidesPerView: 2,
						spaceBetween: 15,
					},
					992: {
						slidesPerView: 3,
						spaceBetween: 30,
					},
					1200: {
						slidesPerView: 5,
						spaceBetween: 20,
					},
				}}
			>
				{blogs
					? blogs.length > 0
						? blogs.map((n, index) => (
								<div key={index}>
									{" "}
									<SwiperSlide>
										<div className="blog-slide swiper-slide ">
											<a href={`/blog/${n["url"]}`}>
												<div className="blog-s-date">
													<h3
														style={{
															color: "#033780",
															fontWeight: "700",
															textAlign: "right",
														}}
													>
														{formatDateToDdMmYyyy(n["publish-date"])}
													</h3>
												</div>
												<div className="blog-slide-image  ">
													<LazyLoadImage
														src={
															n["home-page-image"] && n["home-page-image"].thumb
														}
														alt="worth"
														width="403"
														height="403"
													/>
												</div>
												<div className="blog-slide-desc-wrap">
													{n["category"] && (
														<p className="blog-slide-heading">
															{n["category"].title}{" "}
														</p>
													)}

													<p className="blog-slide-desc">{n.title}</p>
												</div>
											</a>
										</div>{" "}
									</SwiperSlide>
								</div>
						  ))
						: ""
					: ""}
			</Swiper>
			<div className="scroll-bar-wrap">
				<div className="scroll-bar">
					<div className="drag-wrap">
						<div className="drag-icon">
							<LazyLoadImage
								src={drag}
								alt=""
								width="21"
								height="21"
							/>
						</div>
						<h4 className="drag-heading">Drag to see more</h4>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Blog;
