import React, { useState, useEffect } from "react";
import { Nav, Navbar, Accordion, Offcanvas } from "react-bootstrap";
import { NavLink, Link, useLocation } from "react-router-dom";
import { api_url, siteKey } from "Variables";
import { Spinner } from "react-bootstrap";
import Dropdown from "./HomeComponents/Dropdown";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReCAPTCHA from "react-google-recaptcha";

/* // !---------------------------------- Completed ---------------------------------- */

function NavbarHome(props) {
	const [servicesDropDown, setServicesDropDown] = useState(false);
	const [sticky, setSticky] = useState(false);
	const [speakExpert, setSpeakExpert] = useState(false);
	const [links, setLinks] = useState("");
	const [logo, setLogo] = useState("");
	const [pages, setPages] = useState([]);
	const [fullName, setFullName] = useState([]);
	const [company, setCompany] = useState([]);
	const [email, setEmail] = useState([]);
	const [phone, setPhone] = useState([]);
	const [interested, setInterest] = useState([]);
	const [message, setMessage] = useState([]);
	const [budget, setBudget] = useState([]);
	const [btnLoader, setBtnLoader] = useState(false);
	const location = useLocation();
	const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);

	function onChange(value) {
		setIsCaptchaSuccess(true);
		// console.log("captcha value: ", value);
	}

	useEffect(() => {
		SiteInfo();
		Pages();
		window.addEventListener("scroll", () => {
			setSticky(window.scrollY > 50);
		});
	}, []);

	useEffect(() => {
		console.log("Dropdown", servicesDropDown);
	}, [servicesDropDown]);

	const SiteInfo = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "site-info?custom_sort=1", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setLinks(result[0]);
				setLogo(result[0].logo.thumb);
			})
			.catch((error) => console.log("error", error));
	};

	const Pages = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "pages?custom_sort=1", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setPages(result);
			})
			.catch((error) => console.log("error", error));
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		setBtnLoader(true);
		var myHeaders = new Headers();
		myHeaders.append("Accept", "application/json");
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append(
			"Authorization",
			"Bearer P6h5zF1rN56SWVwMxEvFOObzu8099opz4jVpOEsu"
		);

		var raw = JSON.stringify({
			"full-name": fullName,
			company: company,
			email: email,
			phone: phone,
			interested: interested,
			message: message,
			budget: budget,
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(api_url + "contact-us", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log("Form submitted successfully:", result);
				setTimeout(() => {
					setBtnLoader(false);
					setIsCaptchaSuccess(false);
				}, 2000);
				window.location.href = "/thankyou";
			})
			.catch((error) => {
				console.log("error", error);
				setIsCaptchaSuccess(false);
			});
	};

	return (
		<>
			<Navbar
				collapseOnSelect
				expand="lg"
				className={
					sticky
						? "navbar-home sticky"
						: "navbar-home" + (props.className ? ` ${props.className}` : "")
				}
			>
				<Navbar.Brand href="#home">
					<div className="brand-logo">
						<Link to="/" className="brand-logo-anchor">
							<LazyLoadImage
								src={logo}
								alt="site-logo"
								className="lazy-img web-logo"
							/>
						</Link>
					</div>
				</Navbar.Brand>

				<Navbar.Toggle aria-controls="responsive-navbar-nav" />

				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="me-auto"></Nav>
					<Nav>
						<ul className="nav-menu">
							{pages
								? pages.length > 0
									? pages.map((n, index) => (
											<li
												key={index}
												className="nav-menu-item "
												onMouseEnter={() => {
													if (n.title === "Services") {
														setServicesDropDown(true);
													}
												}}
												onMouseLeave={() => {
													if (n.title !== "Services") {
														setServicesDropDown(false);
													}
												}}
											>
												<NavLink
													to={n.url}
													exact={true}
													activeClassName={
														location.pathname === n.url
															? "nav-menu-item-link active"
															: "nav-menu-item-link"
													}
												>
													{n.title}
												</NavLink>
											</li>
									  ))
									: ""
								: ""}
						</ul>
						<div
							className="dg-social-wrapper d-flex justify-content center align-item-center"
							onMouseOver={() => setServicesDropDown(false)}
						>
							<div
								className="dg-a-wrap project-a"
								onClick={() => {
									setSpeakExpert(true);
									setIsCaptchaSuccess(false);
								}}
							>
								<a className="nav-menu-open" href="#">
									<span> Speak to an Expert</span>
								</a>
							</div>
							<div className="dg-a-wrap whatsapp-anchor">
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={`https://wa.me/${links.whatsapp}`}
								>
									<svg
										width="26"
										height="26"
										viewBox="0 0 26 26"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M13 0.199997C5.93115 0.199997 0.199951 5.9312 0.199951 13C0.199951 15.4013 0.874449 17.6401 2.02495 19.5604L0.314535 25.8L6.68745 24.1271C8.55126 25.1867 10.7029 25.8 13 25.8C20.0688 25.8 25.7999 20.0688 25.7999 13C25.7999 5.9312 20.0688 0.199997 13 0.199997ZM8.6187 7.02916C8.8267 7.02916 9.04042 7.0279 9.22495 7.0375C9.45322 7.04283 9.70167 7.05955 9.93953 7.58541C10.2222 8.21048 10.8377 9.77856 10.9166 9.9375C10.9956 10.0964 11.0515 10.2837 10.9416 10.4917C10.8371 10.705 10.7827 10.8341 10.6291 11.0229C10.4702 11.2064 10.296 11.4342 10.152 11.5729C9.9931 11.7318 9.82898 11.9061 10.0125 12.2229C10.1959 12.5397 10.8331 13.5783 11.7749 14.4167C12.9856 15.4983 14.0071 15.8306 14.325 15.9896C14.6428 16.1485 14.8269 16.1237 15.0104 15.9104C15.1992 15.7024 15.8033 14.9887 16.0166 14.6708C16.2246 14.353 16.437 14.408 16.725 14.5125C17.0172 14.617 18.5758 15.3848 18.8937 15.5437C19.2116 15.7027 19.42 15.7813 19.5 15.9104C19.5821 16.0437 19.5822 16.6785 19.3187 17.4187C19.0552 18.1579 17.7615 18.8728 17.1812 18.9229C16.5956 18.9773 16.0491 19.1861 13.3749 18.1333C10.1494 16.8629 8.11513 13.5592 7.9562 13.3458C7.79727 13.1378 6.66453 11.6282 6.66453 10.0708C6.66453 8.50816 7.48385 7.74286 7.77078 7.425C8.06305 7.10713 8.40537 7.02916 8.6187 7.02916Z"
											fill="white"
										/>
									</svg>
								</a>
							</div>
							<div className="dg-a-wrap">
								<a href={`tel: ${links["contact-no"]}`}>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M1.33333 0C0.749333 0 0 0.437333 0 1.33333C0 7.38533 2.4544 13.1643 6.6224 17.3776C10.8357 21.5456 16.6147 24 22.6667 24C23.5627 24 24 23.2507 24 22.6667V17.987C24 17.2603 23.4246 16.6684 22.6979 16.651C21.8579 16.631 20.8008 16.5809 20.1328 16.4635C19.4101 16.3355 18.5391 16.0891 17.9271 15.8984C17.4471 15.7491 16.9263 15.8797 16.5703 16.2344L13.6146 19.1745C11.5719 18.0985 9.93481 16.8439 8.54948 15.4505C7.15615 14.0652 5.90152 12.4281 4.82552 10.3854L7.76562 7.42969C8.12029 7.07369 8.2509 6.55031 8.10156 6.07031C7.91223 5.45965 7.66313 4.58985 7.53646 3.86719C7.41779 3.19919 7.37029 2.14208 7.34896 1.30208C7.33162 0.575417 6.73969 0 6.01302 0H1.33333Z"
											fill="white"
										/>
									</svg>
								</a>
							</div>
						</div>
					</Nav>
				</Navbar.Collapse>
			</Navbar>

			{servicesDropDown && (
				<div
					onMouseOver={() => {
						setServicesDropDown(true);
					}}
					onMouseLeave={() => {
						setServicesDropDown(false);
					}}
				>
					<Dropdown />{" "}
				</div>
			)}

			<div className="mbl-view">
				<div className="mbl-view-social-wrap">
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`https://wa.me/${links.whatsapp}`}
					>
						<svg
							width="26"
							height="26"
							viewBox="0 0 26 26"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M13 0.199997C5.93115 0.199997 0.199951 5.9312 0.199951 13C0.199951 15.4013 0.874449 17.6401 2.02495 19.5604L0.314535 25.8L6.68745 24.1271C8.55126 25.1867 10.7029 25.8 13 25.8C20.0688 25.8 25.7999 20.0688 25.7999 13C25.7999 5.9312 20.0688 0.199997 13 0.199997ZM8.6187 7.02916C8.8267 7.02916 9.04042 7.0279 9.22495 7.0375C9.45322 7.04283 9.70167 7.05955 9.93953 7.58541C10.2222 8.21048 10.8377 9.77856 10.9166 9.9375C10.9956 10.0964 11.0515 10.2837 10.9416 10.4917C10.8371 10.705 10.7827 10.8341 10.6291 11.0229C10.4702 11.2064 10.296 11.4342 10.152 11.5729C9.9931 11.7318 9.82898 11.9061 10.0125 12.2229C10.1959 12.5397 10.8331 13.5783 11.7749 14.4167C12.9856 15.4983 14.0071 15.8306 14.325 15.9896C14.6428 16.1485 14.8269 16.1237 15.0104 15.9104C15.1992 15.7024 15.8033 14.9887 16.0166 14.6708C16.2246 14.353 16.437 14.408 16.725 14.5125C17.0172 14.617 18.5758 15.3848 18.8937 15.5437C19.2116 15.7027 19.42 15.7813 19.5 15.9104C19.5821 16.0437 19.5822 16.6785 19.3187 17.4187C19.0552 18.1579 17.7615 18.8728 17.1812 18.9229C16.5956 18.9773 16.0491 19.1861 13.3749 18.1333C10.1494 16.8629 8.11513 13.5592 7.9562 13.3458C7.79727 13.1378 6.66453 11.6282 6.66453 10.0708C6.66453 8.50816 7.48385 7.74286 7.77078 7.425C8.06305 7.10713 8.40537 7.02916 8.6187 7.02916Z"
								fill="white"
							/>
						</svg>
					</a>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`tel: ${links["contact-no"]}`}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1.33333 0C0.749333 0 0 0.437333 0 1.33333C0 7.38533 2.4544 13.1643 6.6224 17.3776C10.8357 21.5456 16.6147 24 22.6667 24C23.5627 24 24 23.2507 24 22.6667V17.987C24 17.2603 23.4246 16.6684 22.6979 16.651C21.8579 16.631 20.8008 16.5809 20.1328 16.4635C19.4101 16.3355 18.5391 16.0891 17.9271 15.8984C17.4471 15.7491 16.9263 15.8797 16.5703 16.2344L13.6146 19.1745C11.5719 18.0985 9.93481 16.8439 8.54948 15.4505C7.15615 14.0652 5.90152 12.4281 4.82552 10.3854L7.76562 7.42969C8.12029 7.07369 8.2509 6.55031 8.10156 6.07031C7.91223 5.45965 7.66313 4.58985 7.53646 3.86719C7.41779 3.19919 7.37029 2.14208 7.34896 1.30208C7.33162 0.575417 6.73969 0 6.01302 0H1.33333Z"
								fill="white"
							/>
						</svg>
					</a>
				</div>
				<div
					className="mbl-view-get-quote"
					onClick={() => {
						setSpeakExpert(true);
						setIsCaptchaSuccess(false);
					}}
				>
					<a className="nav-menu-open">Get a quote</a>
				</div>
			</div>

			<Offcanvas
				show={speakExpert}
				onHide={() => {
					setSpeakExpert(false);
					setIsCaptchaSuccess(false);
				}}
				className="speak-expert-canvas"
			>
				<Offcanvas.Header closeButton></Offcanvas.Header>
				<Offcanvas.Body>
					<h1 className="text-black mb-5">Speak to an Expert</h1>
					<div class="speak-expert-form col-100">
						<form
							method="POST"
							autocomplete="off"
							onSubmit={handleFormSubmit}
							formenctype="application/json"
						>
							<div class="speak-expert-form-wrap twocol-row">
								<div class="col-31 form-item">
									<div class="input-container">
										<input
											id="fullname"
											class="input"
											name="fullname"
											type="text"
											placeholder=" "
											onChange={(e) => setFullName(e.target.value)}
											required
										/>
										<div class="cut"></div>
										<label for="fullname" class="placeholder">
											Full Name*
										</label>
									</div>
								</div>
								<div class="col-31 form-item">
									<div class="input-container">
										<input
											id="company"
											class="input"
											name="company"
											type="text"
											placeholder=" "
											required
											onChange={(e) => setCompany(e.target.value)}
										/>
										<div class="cut"></div>
										<label for="company" class="placeholder">
											Company*
										</label>
									</div>
								</div>
								<div class="col-31 form-item">
									<div class="input-container">
										<input
											id="email"
											class="input"
											name="email"
											type="email"
											placeholder=" "
											required
											onChange={(e) => setEmail(e.target.value)}
										/>
										<div class="cut"></div>
										<label for="email" class="placeholder">
											Email*
										</label>
									</div>
								</div>
								<div class="col-31 form-item">
									<div class="input-container">
										<input
											id="phone"
											class="input"
											name="phone"
											type="text"
											placeholder=" "
											required
											onChange={(e) => setPhone(e.target.value)}
										/>
										<div class="cut"></div>
										<label for="phone" class="placeholder">
											Phone*
										</label>
									</div>
								</div>
								<div class="col-31 form-item">
									<div class="input-container">
										<select
											class="input-select"
											name="interest"
											id="interest"
											required
											onChange={(e) => setInterest(e.target.value)}
										>
											<option selected disabled>
												I'm interested in
											</option>
											<option value="Website / CMS / CRM Design & Development">
												Website / CMS / CRM Design & Development
											</option>
											<option value="E-Commerce Solution">
												E-Commerce Solution
											</option>
											<option value="Mobile App /Android/ iOS">
												Mobile App /Android/ iOS
											</option>
											<option value="Digital marketing">
												Digital marketing
											</option>
											<option value="UI/UX Design">UI/UX Design</option>
											<option value="AR/VR">AR/VR</option>
											<option value="IT Resource Outsourcing">
												IT Resource Outsourcing
											</option>
											<option value="Branding and Creative Design">
												Branding and Creative Design
											</option>
											<option value="Other">Other</option>
										</select>
									</div>
								</div>
								<div class="col-31 form-item">
									<div class="input-container">
										<select
											class="input-select"
											id="budget"
											name="budget"
											required
											onChange={(e) => setBudget(e.target.value)}
										>
											<option selected disabled>
												Budget
											</option>
											<option value="0 - 500 USD">0 - 500 USD</option>
											<option value="500 USD - 1000 USD">
												500 USD - 1000 USD
											</option>
											<option value="1000USD - 1500 USD">
												1000USD - 1500 USD
											</option>
											<option value="1500 USD - 2000 USD">
												1500 USD - 2000 USD
											</option>
											<option value="2000 USD+">2000+ USD</option>
										</select>
									</div>
								</div>
								<div class="col-100 form-item">
									<div
										class="input-container textArea "
										style={{ zIndex: "0" }}
									>
										<textarea
											id="text-area"
											class="input"
											name="message"
											placeholder=" "
											required
											onChange={(e) => setMessage(e.target.value)}
										></textarea>
										<div class="cut"></div>
										<label for="text-area" class="placeholder">
											Message
										</label>
									</div>
								</div>

								<div
									id="submit-button-getquote"
									class="primary-anchor  form-item"
								>
									{btnLoader ? (
										<Spinner animation="border" variant="dark" size="lg" />
									) : (
										<button
											disabled={!isCaptchaSuccessful}
											class="btn--submit"
											type="submit"
										>
											SUBMIT
										</button>
									)}
								</div>
								<ReCAPTCHA sitekey={siteKey} onChange={onChange} />
								
							</div>
						</form>
					</div>
				</Offcanvas.Body>
			</Offcanvas>
		</>
	);
}

export default NavbarHome;
