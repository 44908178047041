import React, { useState, useEffect } from "react";
import NavbarHome from "../Components/NavbarHome";
import FloatingIcons from "../Components/FloatingIcons";
import HeroSection from "../Components/HomeComponents/HeroSection";
import Services from "../Components/HomeComponents/Services";
import OurWork from "../Components/HomeComponents/OurWork";
import Brands from "../Components/HomeComponents/Brands";
import Awards from "../Components/HomeComponents/Awards";
import FAQ from "../Components/HomeComponents/FAQ";
import Footer from "../Components/Footer";
import Conversation from "../Components/HomeComponents/Conversation";
import Blog from "../Components/HomeComponents/Blog";
import { withRouter } from "react-router-dom";
import Testimonial from "Components/HomeComponents/Testimonials";
import Industries from "Components/HomeComponents/Industries";
import { Helmet } from "react-helmet-async";
import { api_url } from "Variables";
import { LazyLoadImage } from "react-lazy-load-image-component";

/* //!---------------------------------- Completed ---------------------------------- */

function Home() {
	const [meta, setMeta] = useState("");
	const currentUrl = window.location.href;
	const [logo, setLogo] = useState("");

	useEffect(() => {
		MetaTags();
		SiteInfo();
	}, []);

	const MetaTags = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "pages?where[url]=/", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log("pages", result[0]["meta-title"]);
				setMeta(result);
			})
			.catch((error) => console.log("error", error));
	};

	const SiteInfo = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "site-info?custom_sort=1", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log("site", result[0].logo.thumb);
				setLogo(result[0].logo.thumb);
			})
			.catch((error) => console.log("error", error));
	};
	return (
		<div>
			{logo && (
				<Helmet>
					{meta
						? meta.length > 0
							? meta.map((n, index) => (
									<>
										<title>{`${
											n["meta-title"] || "Codiro Technologies"
										}`}</title>
										<meta
											name="description"
											content={`${
												n["meta-description"] || "From Ideas to Reality"
											}`}
										/>
										<meta
											property="og:title"
											content={`${n["meta-title"] || "Codiro Technologies"}`}
										/>
										<meta
											property="og:description"
											content={`${
												n["meta-description"] || "From Ideas to Reality"
											}`}
										/>
										<meta
											property="og:image"
											content={`${
												(n["meta-image"] && n["meta-image"]["thumb"]) || 
													logo
											}`}
										/>
										<meta property="og:url" content={currentUrl} />

										<meta property="og:type" content="website" />
										<meta property="og:site_name" content="codiro.co" />
										<meta property="twitter:site" content="@codiro" />
										<meta
											property="twitter:card"
											content="summary_large_image"
										/>

										<meta
											property="twitter:image"
											content={`${
												(n["meta-image"] && n["meta-image"]["thumb"]) ||
													logo
											}`}
										/>
										<meta
											property="twitter:title"
											content={`${n["meta-title"] || "Codiro Technologies"}`}
										/>
										<meta
											property="twitter:description"
											content={`${
												n["meta-description"] || "From Ideas to Reality"
											}`}
										/>

										<meta http-equiv="x-dns-prefetch-control" content="on" />
									</>
							  ))
							: ""
						: ""}
				</Helmet>
			)}
			<NavbarHome />
			<FloatingIcons />
			<div id="butter">
				<main>
					<HeroSection />
					<Services />
					<Industries />
					<OurWork />
					<Brands />
					<Awards />
					<Testimonial />
					<FAQ />
					<Blog />
					<Conversation />
				</main>
				<Footer />
			</div>
		</div>
	);
}

export default withRouter(Home);
