import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/Styles/custom-home.min.css";
import "./Assets/Styles/custom.min.css";
import "./Assets/Styles/styles.css";
import Home from "./Pages/Home";
import Aos from "aos";
import "aos/dist/aos.css";
import Cursor from "./Components/Cursor";
import { BrowserRouter, Route, withRouter, Switch } from "react-router-dom";
import Services from "Pages/Services";
import About from "Pages/About";
import CaseStudies from "Pages/CaseStudies";
import Careers from "Pages/Careers";
import Blog from "Pages/Blog";
import ServiceDetail from "Pages/ServiceDetail";
import Details from "Pages/Details";
import CaseDetails from "Pages/CaseDetails";
import BlogDetails from "Pages/BlogDetails";
import Thanks from "./Components/ThankYou";
import Apply from "Pages/Apply";
import { HelmetProvider } from 'react-helmet-async';

class App extends Component {
	componentDidMount = () => {
		Aos.init({ duration: 3000 });
	};
	render() {
		return (
			<>
				<HelmetProvider>
				<Cursor />
				<BrowserRouter>
					<Switch>
						<Route exact path="/" component={withRouter(Home)} />
						<Route exact path="/services" component={withRouter(Services)} />
						<Route path="/about" component={withRouter(About)} />
						<Route
							exact
							path="/case-studies"
							component={withRouter(CaseStudies)}
						/>
						<Route
							path="/case-studies/:url"
							component={withRouter(CaseDetails)}
						/>
						<Route exact path="/careers" component={withRouter(Careers)} />
						<Route exact path="/blog" component={withRouter(Blog)} />
						<Route exact
							path="/services/:url"
							component={withRouter(ServiceDetail)}
						/>
						<Route
							path="/services/:url1/:url2"
							component={withRouter(Details)}
						/>

						<Route path="/blog/:url" component={withRouter(BlogDetails)} />
						<Route path="/careers/apply/:url" component={withRouter(Apply)} />
						<Route path="/thankyou" component={withRouter(Thanks)} />
					</Switch>
				</BrowserRouter>
				</HelmetProvider>
			</>
		);
	}
}

export default App;
