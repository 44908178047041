import React, { useEffect, useState, useRef } from "react";
import {Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { api_url } from "Variables";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { LazyLoadImage } from "react-lazy-load-image-component";

gsap.registerPlugin(ScrollTrigger);

function Testimonial() {
	const [careers, setCareers] = useState("");
	const [employee_image, setEmpImage] = useState("");
	const [employees, setEmp] = useState([]);
	
	useEffect(() => {
		getAllEmployees();
		getAllCareers();
	}, []);
	const getAllEmployees = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "our-employees?custom_sort=1", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setEmp(result);
				scrollText();
			})
			.catch((error) => console.log("error", error));
	};
	const scrollText = () => {
		gsap.to("#careersHeading", {
			x: 150,
			duration: 0.55,
			scrollTrigger: {
				trigger: "#careersHeading",
				start: "-200px center",
				end: "800px center",
				scrub: 1,
			},
		});
	};
	const testimonial = {
		dots: false,
		infinite: false,
		slidesToShow: 2,
		slidesToScroll: 1,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	const getAllCareers = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "careers?custom_sort=1", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setCareers(result[0]);
				setEmpImage(result[0]["employees-section-image"].full_url);
			})
			.catch((error) => console.log("error", error));
	};

	return (
		<section className="testimonial py-5 container ">
			<Row>
				<Col md={6} xs={12}>
					<div className="hero-main">
						<h3 className="hero-main-heading fw-700 text-black">
							{careers["employees-section-text-1"]} <br />
							<span className="primary-col">
								{careers["employees-section-text-2"]}
							</span>
						</h3>
					</div>
					<div className="img-box mt-5">
						<LazyLoadImage src={employee_image} alt="employees" className="" />
					</div>
				</Col>
				<Col md={6} xs={12}>
					<Slider {...testimonial} className="testimonial-slider">
						{employees
							? employees.length > 0
								? employees.map((n, index) => (
										<div
											key={index}
											class="swiper-slide swiper-slide-visible swiper-slide-active"
											role="group"
											aria-label="4 / 4"
											style="width: 318px; opacity: 1; transform: translate3d(-954px, 0px, 0px); transition-duration: 0ms;"
										>
											<div class="profileImgWrapper">
												<div class="transparentRadius">
													<div class="profileImg">
														<LazyLoadImage
															class=""
															alt="employee"
															src={n.image.full_url}
														/>
													</div>
												</div>
												<div class="colorRadius"></div>
											</div>
											<div class="profileName">{n.name}</div>
											<div class="profileDesignation">{n.designation}</div>
											<p class="profileDescrip">
												<span
													dangerouslySetInnerHTML={{
														__html: n.views,
													}}
												></span>
											</p>
										</div>
								  ))
								: ""
							: ""}
					</Slider>
				</Col>
			</Row>
		</section>
	);
}

export default Testimonial;
