import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { api_url } from "Variables";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { LazyLoadImage } from "react-lazy-load-image-component";
gsap.registerPlugin(ScrollTrigger);

/*//! -------------------------------- Completed ------------------------------- */

function Awards() {
	const [award, setAwards] = useState("");
	const [award_images, setAwardImages] = useState([]);
	const [background, setBg] = useState("");
	const headingRef = useRef(null);

	useEffect(() => {
		awards();
	}, []);

	useLayoutEffect(() => {
		ScrollTrigger.refresh();
		const awardsHeading = document.getElementById("awardsHeading");
		if (awardsHeading) {
			gsap.to(awardsHeading, {
				x: 150,
				duration: 0.55,
				scrollTrigger: {
					trigger: awardsHeading,
					start: "-300px center",
					end: "450px center",
					scrub: 1,
				},
			});
		}
	});

	const awards = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "home-awards-section?custom_sort=1", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setAwards(result[0]);
				setAwardImages(result[0].images);
				setBg(result[0]["background-image"].thumb);
				document.getElementById("award_desc").innerHTML = result[0].description;
			})
			.catch((error) => console.log("error", error));
	};
	
	return (
		<section className="awards section" data-delay="0s">
			<div className="awards-bg-img">
				<LazyLoadImage
					src={background}
					width="1920"
					height="1040"
					alt="award"
				/>
			</div>
			<div className="line-heading translateX-right">
				<h3 className="outline-text" id="awardsHeading" ref={headingRef}>
					{award.title}{" "}
				</h3>
			</div>
			<div className="container">
				<div className="awards-wrap twocol-row translateY-parent">
					<div className="awards__leftsec col-50">
						<h1
							className="awards-left-heading fadein-Up"
							data-aos="fade-up"
							data-aos-duration="1500"
						>
							{award["line-1-text"]} <span>{award["line-2-text"]}</span>{" "}
						</h1>
						<div
							id="award_desc"
							data-aos="fade-up"
							data-aos-duration="1500"
						></div>
					</div>
					<div className="awards__rightsec col-50 translateY-top">
						<div
							className="awards__rightsec--wrapper twocol-row"
							data-aos="fade-up"
							data-aos-duration="1500"
						>
							{award_images
								? award_images.length > 0
									? award_images.map((n, index) => (
											<div className="awards-right-card" key={index}>
												<a href="/">
													<LazyLoadImage
														src={n.thumb}
														width="130"
														height="125"
														alt="award"
													/>
												</a>
											</div>
									  ))
									: ""
								: ""}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Awards;
