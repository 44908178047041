import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { api_url } from "Variables";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Dropdown() {
	const [service_categories, setServiceCategories] = useState([]);
	const [hoveredItem, setHoveredItem] = useState(null);
	const [services, setServices] = useState([]);
	const [thumbImages, setThumbImages] = useState({});

	useEffect(() => {
		const storedCategories = localStorage.getItem("serviceCategories");
		if (storedCategories) {
			setServiceCategories(JSON.parse(storedCategories));
			setHoveredItem(JSON.parse(storedCategories)[0]);
			const storedServices = localStorage.getItem(
				`services_${JSON.parse(storedCategories)[0].id}`
			);
			if (storedServices) {
				setServices(JSON.parse(storedServices));
				updateThumbImages(JSON.parse(storedServices));
			} else {
				fetchServicesByCategory(JSON.parse(storedCategories)[0].id);
			}
		} else {
			servicesCategories();
		}
	}, []);

	useEffect(() => {
		updateThumbImages(services);
	}, [services]);

	const updateThumbImages = (serviceData) => {
		const updatedThumbImages = {};
		serviceData.forEach((service) => {
			const storedThumbImage = localStorage.getItem(
				`serviceImageThumb_${service.id}`
			);
			if (storedThumbImage) {
				updatedThumbImages[service.id] = storedThumbImage;
			} else {
				updatedThumbImages[service.id] = service["service-page-image"].full_url;
				localStorage.setItem(
					`serviceImageThumb_${service.id}`,
					service["service-page-image"].full_url
				);
			}
		});

		setThumbImages(updatedThumbImages);
	};

	const handleHover = (data) => {
		setHoveredItem(data);
		const storedServices = localStorage.getItem(`services_${data.id}`);
		if (storedServices) {
			setServices(JSON.parse(storedServices));
		} else {
			fetchServicesByCategory(data.id);
		}
	};

	const fetchServicesByCategory = async (categoryId) => {
		const requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(`${api_url}services?where[category]=${categoryId}`, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				localStorage.setItem(`services_${categoryId}`, JSON.stringify(data));
				setServices(data);
			})
			.catch((error) => console.log("error", error));
	};

	const servicesCategories = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(`${api_url}service-categories?custom_sort=1`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				localStorage.setItem("serviceCategories", JSON.stringify(result));
				setServiceCategories(result);
				setHoveredItem(result[0]);
				const storedServices = localStorage.getItem(`services_${result[0].id}`);
				if (storedServices) {
					setServices(JSON.parse(storedServices));
				} else {
					fetchServicesByCategory(result[0].id);
				}
				console.log("service categories", service_categories);
			})
			.catch((error) => console.log("error", error));
	};

	return (
		<div className={`services-dropdown`}>
			<Row>
				<Col md={3} className="services-dleft">
					{service_categories
						? service_categories.length > 0
							? service_categories.map((n, index) => (
									<a
										href={`/services/${n["url"]}`}
										key={index}
										onMouseEnter={() => handleHover(n)}
									>
										{n["service-name"]}
									</a>
							  ))
							: ""
						: ""}
				</Col>
				<Col md={9}>
					{hoveredItem && (
						<div>
							<div className="d-header">
								<h1>{hoveredItem["service-name"]}</h1>
							</div>
							<div className="d-body">
								{services.map((service, index) => (
									<div key={index} className="d-item">
										{thumbImages[service.id] && (
											<LazyLoadImage
												className="d-img"
												src={thumbImages[service.id]}
												alt=""
												loading="eager"
											/>
										)}
										<a
											href={
												`/services/` +
												service["category"].url +
												`/` +
												service.url
											}
										>
											{service.title}
										</a>
									</div>
								))}
							</div>
						</div>
					)}
				</Col>
			</Row>
		</div>
	);
}

export default Dropdown;
