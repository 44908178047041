import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import NavbarHome from "../Components/NavbarHome";
import { Offcanvas } from "react-bootstrap";
import { useParams } from "react-router-dom";
import FloatingIcons from "../Components/FloatingIcons";
import Footer from "../Components/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { api_url, siteKey } from "Variables";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReCAPTCHA from "react-google-recaptcha";

gsap.registerPlugin(ScrollTrigger);

/* //! ------------------------------------ Completed ----------------------------------- */

function Apply() {
	const [careers, setCareers] = useState("");
	const [culture_left_image, setLeftImage] = useState("");
	const [culture_right_images, setRightImage] = useState([]);
	const [employee_image, setEmpImage] = useState("");
	const [positions, setPosition] = useState([]);
	const [employees, setEmp] = useState([]);
	const headingRef = useRef(null);
	const [speakExpert, setSpeakExpert] = useState(false);
	const { url } = useParams();
	const [selectedFileName, setSelectedFileName] = useState("No file chosen");
	const [fullName, setFullName] = useState([]);
	const [email, setEmail] = useState([]);
	const [phone, setPhone] = useState([]);
	const [address, setAddress] = useState([]);
	const [city, setCity] = useState([]);
	const [country, setCountry] = useState([]);
	const [date, setDate] = useState([]);
	const [pay, setPay] = useState([]);
	const [btnLoader, setBtnLoader] = useState(false);
	const [resume, setResume] = useState([]);
	const [empResume, setEmpResume] = useState("");
	const [meta, setMeta] = useState("");
	const currentUrl = window.location.href;
	const [logo, setLogo] = useState("");
	const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);

	function onChange(value) {
		setIsCaptchaSuccess(true);
		// console.log("captcha value: ", value);
	}

	useEffect(() => {
		getAllCareers();
		getAllPositions();
		getAllEmployees();
		SiteInfo();
	}, []);

	const SiteInfo = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "site-info?custom_sort=1", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log("site", result[0].logo.thumb);
				setLogo(result[0].logo.thumb);
			})
			.catch((error) => console.log("error", error));
	};

	useLayoutEffect(() => {
		gsap.to("#careersHeading", {
			x: 150,
			duration: 0.55,
			scrollTrigger: {
				trigger: "#careersHeading",
				start: "-200px center",
				end: "800px center",
				scrub: 1,
			},
		});
	}, []);

	const getAllCareers = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "careers?custom_sort=1", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setCareers(result[0]);
				setLeftImage(result[0]["culture-left-section-image"].full_url);
				setRightImage(result[0]["culture-right-images"]);
				setEmpImage(result[0]["employees-section-image"].full_url);
			})
			.catch((error) => console.log("error", error));
	};

	const getAllPositions = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + `positions?custom_sort=1&where[url]=${url}`, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setPosition(result);
				setMeta(result);
			})
			.catch((error) => console.log("error", error));
	};

	const getAllEmployees = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "our-employees?custom_sort=1", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setEmp(result);
			})
			.catch((error) => console.log("error", error));
	};

	const handleButtonClick = (e) => {
		e.preventDefault();
		document.getElementById("getFile").click();
	};

	const handleFileChange = (event) => {
		const fileInput = event.target;
		setResume(event.target.files[0]);
		const fileName = fileInput.files[0]?.name || "No file chosen";
		setSelectedFileName(fileName);
		setBtnLoader(true);
	};

	useEffect(() => {
		console.log("File selected:", selectedFileName);
		console.log("Resume", resume);
		var myHeaders = new Headers();
		myHeaders.append("Accept", "*/*");
		myHeaders.append(
			"Authorization",
			"Bearer P6h5zF1rN56SWVwMxEvFOObzu8099opz4jVpOEsu"
		);
		var formdata = new FormData();

		formdata.append("file", resume);
		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "project-media/upload", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				const uploadedFileUrl = result.full_url;
				setEmpResume(uploadedFileUrl);
				console.log("File uploaded successfully. URL:", empResume);
				setBtnLoader(false);
			})
			.catch((error) => console.error("Error:", error));
	}, [resume]);

	const handleFormSubmit = (e) => {
		setBtnLoader(true);
		console.log("EMp", empResume);
		var myHeaders = new Headers();
		myHeaders.append("Accept", "*/*");
		myHeaders.append(
			"Authorization",
			"Bearer P6h5zF1rN56SWVwMxEvFOObzu8099opz4jVpOEsu"
		);

		var formdata = new FormData();
		formdata.append("phone-no", phone);
		formdata.append("full-name", fullName);
		formdata.append("email", email);
		formdata.append("address", address);
		formdata.append("country", country);
		formdata.append("city", city);
		formdata.append("expected-joining-date", date);
		formdata.append("desired-salary", pay);
		formdata.append("resume", empResume);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "career-applicants", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log("Form Submit:", result);
				setBtnLoader( false );
				setIsCaptchaSuccess(false);
				window.location.href = "/thankyou";
			})
			.catch((error) => {
				setBtnLoader( false );
				setIsCaptchaSuccess(false);
				console.error("Error:", error);
			});

		return Promise.resolve();
	};

	return (
		<div>
			{logo && (
				<Helmet>
					{meta
						? meta.length > 0
							? meta.map((n, index) => (
									<>
										<title>{`${
											n["meta-title"] || "Codiro Technologies"
										}`}</title>
										<meta
											name="description"
											content={`${
												n["meta-description"] || "From Ideas to Reality"
											}`}
										/>
										<meta
											property="og:title"
											content={`${n["meta-title"] || "Codiro Technologies"}`}
										/>
										<meta
											property="og:description"
											content={`${
												n["meta-description"] || "From Ideas to Reality"
											}`}
										/>
										<meta
											property="og:image"
											content={`${
												(n["meta-image"] && n["meta-image"]["thumb"]) || logo
											}`}
										/>
										<meta property="og:url" content={currentUrl} />

										<meta property="og:type" content="website" />
										<meta property="og:site_name" content="codiro.co" />
										<meta property="twitter:site" content="@codiro" />
										<meta
											property="twitter:card"
											content="summary_large_image"
										/>

										<meta
											property="twitter:image"
											content={`${
												(n["meta-image"] && n["meta-image"]["thumb"]) || logo
											}`}
										/>
										<meta
											property="twitter:title"
											content={`${n["meta-title"] || "Codiro Technologies"}`}
										/>
										<meta
											property="twitter:description"
											content={`${
												n["meta-description"] || "From Ideas to Reality"
											}`}
										/>

										<meta http-equiv="x-dns-prefetch-control" content="on" />
									</>
							  ))
							: ""
						: ""}
				</Helmet>
			)}
			<NavbarHome />
			<FloatingIcons />
			<div id="butter">
				<main>
					<section class="about-us inner-banner inner-hero">
						<div class="container">
							<div class="twocol-row">
								<div class="col-36" data-aos="fade-up" data-aos-duration="1500">
									<div class=" inner-hero-heading hero-main">
										<h6 class="hero-main-subheading left-element">
											{careers["sub-title"]}
										</h6>
										<h1 class="hero-main-heading fw-700">
											{careers["title-1"]}{" "}
											<span class="primary-col">{careers["title-2"]}</span>{" "}
										</h1>
									</div>
								</div>
								<div class="col-30">
									<div
										className="top-desc"
										dangerouslySetInnerHTML={{
											__html: careers["top-description"],
										}}
									></div>
								</div>
							</div>
						</div>
					</section>

					<section class="section currentOpenings job-description">
						{positions
							? positions.length > 0
								? positions.map((n, index) => (
										<div class="container">
											<div
												class="job-description__card  fadein-Up"
												data-delay="0.2s"
											>
												<div class="job-description__cardWrapper">
													<div class="head-wrapper">
														<div class="generic--head">
															<h2 class="generic--head__head fw-700 gray-col capitalize letter-spacing">
																{n.title}
															</h2>
														</div>
													</div>
													<div class="bottom-wrapper"></div>
												</div>
												<div
													class="btn-wrapper apply-btn"
													onClick={() => {setSpeakExpert(true);
														setIsCaptchaSuccess(false);
													}}
												>
													<a class="fill-btn" href="  javascript:">
														Apply Now
													</a>
												</div>
											</div>

											<div class="job-detail-wrapper">
												<div class="description points">
													<div class="generic--head">
														<h3 class="generic--head__head fw-700 s-font gray-col ">
															{n["job-details-title"]}
														</h3>
													</div>
													<div class="desccription-wrapper ">
														<div class="iteam">
															<span
																dangerouslySetInnerHTML={{
																	__html: n["job-details-description"],
																}}
															></span>
														</div>
													</div>
												</div>
												<div class="description dots">
													<div class="generic--head">
														<h3 class="generic--head__head fw-700 s-font gray-col ">
															{n["responsibilities-title"]}
														</h3>
													</div>
													<div class="desccription-wrapper ">
														<span
															dangerouslySetInnerHTML={{
																__html: n["responsibilities-description"],
															}}
														></span>
													</div>
												</div>
											</div>
										</div>
								  ))
								: ""
							: ""}
					</section>

					<section class="our-culture">
						<div class="our-culture-wrap twocol-row">
							<div
								class="our-culture-leftside translateX-parent lets-play popupVideo"
								data-aos="fade-up"
								data-aos-duration="1500"
								data-video="https://www.youtube.com/watch?v=3vl1w8FDUlo"
								style={{
									animation:
										"0.5s ease-out 0.1s 1 normal forwards running anim",
								}}
							>
								<div class="line-heading">
									<h3
										class="outline-text "
										id="careersHeading"
										ref={headingRef}
									>
										{careers["our-culture-section"]}{" "}
									</h3>
								</div>
								<div class="img-box">
									<LazyLoadImage src={culture_left_image} alt="culture" />
								</div>
							</div>
							<div class="our-culture-rightside">
								<div class="our-culture-rightside-wrap col-100">
									{culture_right_images
										? culture_right_images.length > 0
											? culture_right_images.map((n, index) => (
													<div
														key={index}
														class="img-box"
														data-aos="fade-up"
														data-aos-duration="1500"
													>
														<LazyLoadImage src={n.full_url} alt="culture" />
													</div>
											  ))
											: ""
										: ""}
								</div>
							</div>
						</div>
					</section>

					<Offcanvas
						show={speakExpert}
						onHide={ () =>
						{
							setSpeakExpert( false ); 
							setIsCaptchaSuccess(false);
						}}
						className="speak-expert-canvas apply-now-canvas"
						style={{
							position: "fixed",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							maxWidth: "40%",
							height: "80%",
						}}
					>
						<Offcanvas.Header
							closeButton
							style={{ height: "50px" }}
						></Offcanvas.Header>
						<Offcanvas.Body>
							<h6 className="text-black mb-2 mt-0">Enter Your Details</h6>
							<div class="speak-expert-form col-100">
								<form
									method="POST"
									autocomplete="off"
									enctype="multipart/form-data"
									onSubmit={(e) => {
										e.preventDefault();
										handleFormSubmit();
									}}
								>
									<div class="speak-expert-form-wrap twocol-row">
										<div class="col-45 form-item">
											<div class="input-container">
												<input
													id="fullname"
													class="input"
													name="fullname"
													type="text"
													placeholder=" "
													required
													onChange={(e) => setFullName(e.target.value)}
												/>
												<div class="cut"></div>
												<label for="fullname" class="placeholder">
													Full Name*
												</label>
											</div>
										</div>
										<div class="col-45 form-item">
											<div class="input-container">
												<input
													id="email"
													class="input"
													name="email"
													type="email"
													placeholder=" "
													required
													onChange={(e) => setEmail(e.target.value)}
												/>
												<div class="cut"></div>
												<label for="email" class="placeholder">
													Email*
												</label>
											</div>
										</div>
										<div class="col-45 form-item">
											<div class="input-container">
												<input
													id="phone"
													class="input"
													name="phone"
													type="text"
													placeholder=" "
													required
													onChange={(e) => setPhone(e.target.value)}
												/>
												<div class="cut"></div>
												<label for="phone" class="placeholder">
													Phone*
												</label>
											</div>
										</div>
										<div class="col-45 form-item">
											<div
												class="input-container textArea "
												style={{ zIndex: "0" }}
											>
												<input
													id="date"
													class="input"
													name="date"
													type="date"
													required
													onChange={(e) => setDate(e.target.value)}
												/>
												<div class="cut"></div>
												<label for="date" class="placeholder">
													Joining Date*
												</label>
											</div>
										</div>
										<div class="col-31 form-item">
											<div
												class="input-container textArea "
												style={{ zIndex: "0" }}
											>
												<input
													id="address"
													class="input"
													name="address"
													type="text"
													placeholder=" "
													required
													onChange={(e) => setAddress(e.target.value)}
												/>
												<div class="cut"></div>
												<label for="address" class="placeholder">
													Address*
												</label>
											</div>
										</div>
										<div class="col-31 form-item">
											<div
												class="input-container textArea "
												style={{ zIndex: "0" }}
											>
												<input
													id="city"
													class="input"
													name="city"
													type="text"
													placeholder=" "
													required
													onChange={(e) => setCity(e.target.value)}
												/>
												<div class="cut"></div>
												<label for="city" class="placeholder">
													City*
												</label>
											</div>
										</div>
										<div class="col-31 form-item">
											<div
												class="input-container textArea "
												style={{ zIndex: "0" }}
											>
												<input
													id="country"
													class="input"
													name="country"
													type="text"
													placeholder=" "
													required
													onChange={(e) => setCountry(e.target.value)}
												/>
												<div class="cut"></div>
												<label for="country" class="placeholder">
													Country*
												</label>
											</div>
										</div>
										<div class="col-45 form-item">
											<div
												class="input-container textArea "
												style={{ zIndex: "0" }}
											>
												<input
													id="pay"
													class="input"
													name="pay"
													type="text"
													placeholder=" "
													required
													onChange={(e) => setPay(e.target.value)}
												/>
												<div class="cut"></div>
												<label for="pay" class="placeholder">
													Desired Pay*
												</label>
											</div>
										</div>
										<div class="col-45 form-item">
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
												}}
											>
												<button
													style={{
														display: "block",
														width: "160px",
														height: "30px",
														border: "2px dotted #033780",
														borderRadius: "50px",
														fontSize: "12px",
														margin: "10px auto",
													}}
													onClick={handleButtonClick}
												>
													Upload CV*
												</button>
												<input
													type="file"
													id="getFile"
													name="resume"
													required
													style={{ display: "none" }}
													onChange={handleFileChange}
												/>
												<label for="getFile">
													<h6 className="text-black">{selectedFileName}</h6>
												</label>
											</div>
										</div>
									</div>
									<ReCAPTCHA sitekey={siteKey} onChange={onChange} />
									<br />
									<div
										id="submit-button-career"
										class="primary-anchor form-item"
									>
										{btnLoader ? (
											<Spinner animation="border" variant="dark" size="lg" />
										) : (
											<button
												disabled={!isCaptchaSuccessful}
												class="submit-bttn mb-2"
												type="submit"
											>
												SUBMIT
											</button>
										)}
									</div>
								</form>
							</div>
						</Offcanvas.Body>
					</Offcanvas>
				</main>
			</div>
			<Footer />
		</div>
	);
}

export default Apply;
