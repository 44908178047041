import React, { useState, useEffect } from "react";
import drag from "../../Assets/Images/drag-img.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/scrollbar";
import { Grid, Scrollbar } from "swiper/modules";
import { api_url } from "Variables";
import { LazyLoadImage } from "react-lazy-load-image-component";

/* //! -------------------------------- Completed ------------------------------- */

function Brands() {
	const [homebrands, setHomeBrands] = useState("");
	const [brandsLogo, setBrandsLogo] = useState([]);

	useEffect(() => {
		homeBrands();
	}, []);

	const homeBrands = async () => {
		var requestOptions = {
			method: "GET",
			redirect: "follow",
		};

		fetch(api_url + "home-brands?custom_sort=1", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setHomeBrands(result[0]);
				setBrandsLogo(result[0]["companies-logos"]);
			})
			.catch((error) => console.log("error", error));
	};

	return (
		<section className="brands section">
			<div className="container">
				<h2
					className="brands-title fadein-Up-Fast"
					data-delay="0s"
					data-speed="0.5s"
				>
					{homebrands["text-1"]}{" "}
					<span class="primary-col">{homebrands["text-2"]}</span>{" "}
					{homebrands["text-3"]}{" "}
				</h2>
				<Swiper
					slidesPerView={6}
					spaceBetween={30}
					speed={2000}
					scrollbar={true}
					grid={{
						rows: 2,
						fill: "row",
					}}
					modules={[Scrollbar, Grid]}
					className="mySwiper brandSwiper"
					breakpoints={{
						300: {
							slidesPerView: 3,
							spaceBetween: 20,
							grid: {
								rows: 1,
								fill: "row",
							},
						},

						577: {
							slidesPerView: 3,
							spaceBetween: 20,
							grid: {
								rows: 1,
								fill: "row",
							},
						},

						768: {
							slidesPerView: 6,
							spaceBetween: 20,
							grid: {
								rows: 1,
								fill: "row",
							},
						},

						1100: {
							slidesPerView: 7,
							spaceBetween: 20,
							grid: {
								rows: 2,
								fill: "row",
							},
						},
					}}
				>
					{brandsLogo
						? brandsLogo.length > 0
							? brandsLogo.map((n, index) => (
									<div key={index}>
										<div
											className="swiper-slide skew-transition"
											data-delay="0"
										>
											<SwiperSlide>
												<div className="img-box">
													<LazyLoadImage
														className="lazy-img"
														src={n.thumb}
														alt=""
														width="25"
														height="25"
													/>
												</div>
											</SwiperSlide>
										</div>
									</div>
							  ))
							: ""
						: ""}
				</Swiper>

				<div className="scroll-bar-wrap">
					<div className="scroll-bar">
						<div className="drag-wrap">
							<div className="drag-icon">
								<LazyLoadImage
									className="lazy-img"
									src={drag}
									alt=""
									width="21"
									height="21"
								/>
							</div>
							<h4 className="drag-heading">Drag to see more</h4>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Brands;
