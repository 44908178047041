import React, { useState, useEffect } from "react";

/* //! -------------------------------- Completed ------------------------------- */
function Thanks() {
	return (
		<div>
			<title>Thank You - Digital Gravity Agency</title>

			<noscript>
				<iframe
					src="https://www.googletagmanager.com/ns.html?id=GTM-MGSRDSF"
					height="0"
					width="0"
					style="display:none;visibility:hidden"
				></iframe>
			</noscript>

			<div class="notfound_text">
				<h4
					class="fadeInUp animated"
					data-animation="fadeInUp"
					data-duration="100"
				>
					<span>Thank You!</span>
					Awesome!
				</h4>
				<p
					class="fadeInUp animated"
					data-animation="fadeInUp"
					data-duration="100"
				>
					Thankyou for sharing your dream project
					<br />
					with us! One of our experts will get back to you in no time.
				</p>
				<div class="head-btn">
					<a href="/" class="d_anchor">
						Back to Home
					</a>
				</div>
			</div>
		</div>
	);
}

export default Thanks;
